import m0p2 from './images-xledger/m0p2.jpg';
import m0p3 from './images-xledger/m0p3.jpg';
import m0p4 from './images-xledger/m0p4.jpg';
import m0p5 from './images-xledger/m0p5.jpg';

import m1p2 from './images-xledger/m1p2.jpg';
import m1p3 from './images-xledger/m1p3.jpg';

import m2p2 from './images-xledger/m2p2.jpg';
import m2p3 from './images-xledger/m2p3.jpg';

import m3p2 from './images-xledger/m3p2.jpg';
import m3p3 from './images-xledger/m3p3.jpg';

import m4p2 from './images-xledger/m4p2.jpg';
import m4p3 from './images-xledger/m4p3.jpg';
import m4p4 from './images-xledger/m4p4.jpg';
import m4p5 from './images-xledger/m4p5.jpg';
import m4p6 from './images-xledger/m4p6.jpg';

import m5p2 from './images-xledger/m5p2.jpg';
import m5p3 from './images-xledger/m5p3.jpg';

import m6p2 from './images-xledger/m6p2.jpg';
import m6p3 from './images-xledger/m6p3.jpg';

import m7p2 from './images-xledger/m7p2.jpg';
import m7p3 from './images-xledger/m7p3.jpg';

export default {
    memos: [
        {
            pairs: [
                {
                    text: 'Ferdighet 0',
                    image: '0'
                },
                {
                    text: 'Tren hele hjernen',
                    image: m0p2
                },
                {
                    text: 'Trygg reptilhjerne',
                    image: m0p3
                },
                {
                    text: 'Glad emosjons-/pattedyrshjerne',
                    image: m0p4
                },
                {
                    text: ' Balansert menneskehjerne',
                    image: m0p5
                }
            ]
        },
        {
            pairs: [
                {
                    text: 'Ferdighet 1',
                    image: '1'
                },
                {
                    text: 'Fokus',
                    image: m1p2
                },
                {
                    text: 'Fjern forstyrrelser',
                    image: m1p3
                }
            ],
            fakeOptions: [m2p3]
        },
        {
            pairs: [
                {
                    text: 'Ferdighet 2',
                    image: '2'
                },
                {
                    text: 'Produktivitet',
                    image: m2p2
                },
                {
                    text: 'Prioriter',
                    image: m2p3
                }
            ],
            fakeOptions: [m3p3]
        },
        {
            pairs: [
                {
                    text: 'Ferdighet 3',
                    image: '3'
                },
                {
                    text: 'Kreativitet',
                    image: m3p2
                },
                {
                    text: 'Kombiner tanker til en ide',
                    image: m3p3
                }
            ],
            fakeOptions: [m4p3]
        },
        {
            pairs: [
                {
                    text: 'Ferdighet 4',
                    image: '4'
                },
                {
                    text: 'Kommunikasjon',
                    image: m4p2
                },
                {
                    text: 'Repeter',
                    image: m4p3
                },
                {
                    text: 'Evaluer',
                    image: m4p4
                },
                {
                    text: 'Anekdoter for oversikt',
                    image: m4p5
                },
                {
                    text: 'Detaljer for beslutning',
                    image: m4p6
                }
            ]
        },
        {
            pairs: [
                {
                    text: 'Ferdighet 5',
                    image: '5'
                },
                {
                    text: 'Motivasjon',
                    image: m5p2
                },
                {
                    text: 'La kroppsspråket inspirere',
                    image: m5p3
                }
            ],
            fakeOptions: [m6p2]
        },
        {
            pairs: [
                {
                    text: 'Ferdighet 6',
                    image: '6'
                },
                {
                    text: 'Relasjonsbygging',
                    image: m6p2
                },
                {
                    text: 'Minimer kritikk',
                    image: m6p3 // todo
                }
            ],
            fakeOptions: [m7p2]
        },
        {
            pairs: [
                {
                    text: 'Ferdighet 7',
                    image: '7'
                },
                {
                    text: 'Vaneendring',
                    image: m7p2
                },
                {
                    text: 'Bruk triggerne dine',
                    image: m7p3
                }
            ],
            fakeOptions: [m0p4]
        }
    ]
};
