import m1p1 from './images-techdata/image11.png';
import m1p2 from './images-techdata/image9.jpg';
import m1p3 from './images-techdata/image10.png';

import m2p1 from './images-techdata/m2p1.png';
import m2p2 from './images-techdata/m2p2.png';
import m2p3 from './images-techdata/m2p3.png';
import m2p4 from './images-techdata/m2p4.png';
import m2p5 from './images-techdata/m2p5.png';
import m2p6 from './images-techdata/m2p6.png';
import m2p7 from './images-techdata/m2p7.png';

import m3p1 from './images-techdata/m3p1.jpg';
import m3p2 from './images-techdata/image14.jpg';
import m3p3 from './images-techdata/image15.png';

import m4p1 from './images-techdata/m4p1.jpg';
import m4p2 from './images-techdata/m4p2.jpg';
import m4p3 from './images-techdata/image18.jpg';

import m5p1 from './images-techdata/image25.jpg';
import m5p2 from './images-techdata/m5p2.jpg';
import m5p3 from './images-techdata/m5p3.jpg';

import m6p1 from './images-techdata/m6p1.jpg';
import m6p2 from './images-techdata/image28.jpg';
import m6p3 from './images-techdata/m6p3.jpg';

import m7p1 from './images-techdata/m7p1.jpg';
import m7p2 from './images-techdata/m7p2.jpg';
import m7p3 from './images-techdata/image33.png';
import m7p4 from './images-techdata/image34.png';

import m8p1 from './images-techdata/image35.png';
import m8p2 from './images-techdata/image40.jpg';
import m8p3 from './images-techdata/m8p3.jpg';
import m8p4 from './images-techdata/image39.jpg';

import m9p1 from './images-techdata/image41.jpg';
import m9p2 from './images-techdata/m9p2.png';
import m9p3 from './images-techdata/m9p3.png';
import m9p4 from './images-techdata/m9p4.png';

export default {
    memos: [
        {
            pairs: [
                {
                    text: 'Tech Data ønsker velkommen', // Andre Johnsen, Tech Data
                    image: m1p1
                },
                {
                    text: 'Beste og største Software-teamet i Norge',
                    image: m1p2
                },
                {
                    text: 'Fokus = verdiøkning fremfor produktsalg',
                    image: m1p3
                }
            ]
        },
        {
            pairs: [
                {
                    text: 'Ligg et hode foran med fremtidsferdighetene', // Frank Wedde, Memolife
                    image: m2p1
                },
                {
                    text: 'DATE HJERNEN DIN: Reptilhjerne, Emosjonshjerne, Menneskehjerne',
                    image: m2p2
                },
                {
                    text: 'FOKUS: Fjern forstyrrelser',
                    image: m2p3
                },
                {
                    text: 'KREATIVITET: Kombiner tanker',
                    image: m2p4
                },
                {
                    text: 'KOMMUNIKASJON: Snakk til hele hjernen med READ stegene',
                    image: m2p5
                },
                {
                    text: 'MOTIVASJON: Få med kroppen',
                    image: m2p6
                },
                {
                    text: 'RELASJONER: Minimer kritikk',
                    image: m2p7
                }
            ]
        },
        {
            pairs: [
                {
                    text: 'CSP - Enklere med Cloud', // Martin Wiborg, Tech Data
                    image: m3p1
                },
                {
                    text: 'Fleksibiliteten i CSP programmet',
                    image: m3p2
                },
                {
                    text: 'Marginer i CSP',
                    image: m3p3
                }
            ]
        },
        {
            pairs: [
                {
                    text: 'Azure og JFK-saken', // Izabela Hawrylko, Microsoft
                    image: m4p1
                },
                {
                    text: 'Knowledge mining = easy for non-tech',
                    image: m4p2
                },
                {
                    text: 'ROI is high',
                    image: m4p3
                }
            ]
        },
        {
            pairs: [
                {
                    text: 'Hvordan enkelt komme i gang med IoT', // Gjermund Nygård, Tech Data
                    image: m5p1
                },
                {
                    text: 'Mange produsenter = usikkerhet',
                    image: m5p2
                },
                {
                    text: '1 IoT analytics økosystem = sikkerhet',
                    image: m5p3
                }
            ]
        },
        {
            pairs: [
                {
                    text: 'Hvordan starte med Azure?', // Knut Møllevik, Microsoft
                    image: m6p1
                },
                {
                    text: 'Azure = mange muligheter',
                    image: m6p2
                },
                {
                    text: 'Lett å velge tjenester i Azure',
                    image: m6p3
                }
            ]
        },
        {
            pairs: [
                {
                    text: 'Hvordan få kompetanse og hva kan vi tilby?', // Sergei Pedersen, Cecilie Røed Hansen & Martin Wiborg, Tech Data
                    image: m7p1
                },
                {
                    text: 'NetEnrich = 4x arbeidsstyrke',
                    image: m7p2
                },
                {
                    text: 'Kompetanse på alle formater',
                    image: m7p3
                },
                {
                    text: 'CIE sesjon hos Microsoft demo rom',
                    image: m7p4
                }
            ]
        },
        {
            pairs: [
                {
                    text: 'Microsoft 365', // Sergio Prin Calderon, Microsoft
                    image: m8p1
                },
                {
                    text: 'Skattekiste med moderne verktøy',
                    image: m8p2
                },
                {
                    text: 'MS Teams = alle verktøy for teams',
                    image: m8p3
                },
                {
                    text: 'Sikkert & smart',
                    image: m8p4
                }
            ]
        },
        {
            pairs: [
                {
                    text: 'Ny portal', // Sergei Pedersen & Hans Jørgen Johnsrud & Cecilie Røed Hansen, Tech Data
                    image: m9p1
                },
                {
                    text: 'SES = Microsoft Azure + Amazon AWS + IBM Cloud',
                    image: m9p2
                },
                {
                    text: 'Kraftig analyse- og faktureringsverktøy',
                    image: m9p3
                },
                {
                    text: 'Løsninger for samhandling = lett',
                    image: m9p4
                }
            ]
        }
    ]
};
