import heidi1 from './heidi1.png';
import heidi2 from './heidi2.png';
import heidi3 from './heidi3.png';

import eva1 from './eva1.png';
import eva2 from './eva2.png';
import eva3 from './eva3.png';

import aasmund1 from './aasmund1.png';
import aasmund2 from './aasmund2.png';
import aasmund3 from './aasmund3.png';

import oystein1 from './oystein1.png';
import oystein2 from './oystein2.png';
import oystein3 from './oystein3.png';

import camilla1 from './camilla1.png';
import camilla2 from './camilla2.png';
import camilla3 from './camilla3.png';

import frank1 from './frank1.png';
import frank2 from './frank2.png';
import frank3 from './frank3.png';


export default {
    memos: [
        {
            pairs: [
              {
                text: 'Ta markedsandeler',
                image: heidi1,
              },
              {
                text: 'Engasjere oss i bredden',
                image: heidi2,
              },
              {
                text: 'Være nær kunden',
                image: heidi3,
              },
            ],
          fakeOptions: [eva1],
        },
      {
        pairs: [
              {
                text: 'Aldri hatt høyere kundevekst enn i 2021',
                  image: eva1,
              },
              {
                text: 'Kundetilfredshet er det viktigste i 2022',
                image: eva2,
              },
              {
                text: 'Kunder med flere produkter sitter bedre',
                image: eva3,
              },
        ],
        fakeOptions: [heidi1],
      },
      {
        pairs: [
          {
            text: 'Lanserer ny mobil nettbank',
            image: aasmund1,
          },
          {
            text: 'Banken er navet som bredder kunden',
            image: aasmund2,
          },
          {
            text: 'Best på boligbytte - nærmest kunden',
            image: aasmund3,
          },
        ],
        fakeOptions: [heidi2],
      },
      {
        pairs: [
          {
            text: 'Mange små steg skaper store resultater',
            image: oystein1,
          },
          {
            text: 'Sammen er vi sterke',
            image: oystein2,
          },
          {
            text: 'Lønnsom skalering er nøkkelen',
            image: oystein3,
          },
        ],
        fakeOptions: [aasmund2],
      },
      {
        pairs: [
          {
            text: 'Styrket brand = vekst',
            image: camilla1,
          },
          {
            text: 'Datadrevet kundeutvikling',
            image: camilla2,
          },
          {
            text: 'Levere engasjerende kundeopplevelser',
            image: camilla3,
          },
        ],
        fakeOptions: [oystein3],
      },
      {
        pairs: [
          {
            text: 'Start dagen med gledesbilder',
            image: frank1,
          },
          {
            text: 'Kyss frosken før lunsj',
            image: frank2,
          },
          {
            text: 'Lag dagsplanen dagen før',
            image: frank3,
          },
        ]
      },
    ],
};
