import img1 from './images/shopping-1.png';
import img2 from './images/shopping-2.png';
import img3 from './images/shopping-3.png';
import img4 from './images/shopping-4.png';

export default {
    memos: [
        {
            pairs: [
                {
                    text: 'Columbus practiced',
                    image: img1
                },
                {
                    text: 'wash out the seeds',
                    image: img2
                }
            ]
        },
        {
            pairs: [
                {
                    text: 'it is actually a sweet fruit',
                    image: img3
                },
                {
                    text: 'is also botanically a fruit',
                    image: img4
                }
            ]
        }
    ]
};
