import techdata from './techdata'
import aim2north from './aim2north'
import xledger from './xledger'
import tekna from './tekna'
import sats from './sats'
import foobar1 from './foobar1'
import foobar2 from './foobar2'
import technipfmc from './technipfmc/index'
import storebrand from './storebrand/index'

export default {
  techdata,
  aim2north,
  xledger,
  tekna,
  sats,
  foobar1,
  foobar2,
  technipfmc,
  storebrand,
}
