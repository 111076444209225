import img1 from './images/shopping-1.png';
import img2 from './images/shopping-2.png';
import img3 from './images/shopping-3.png';
import img4 from './images/shopping-4.png';
import img5 from './images/shopping-5.png';
import img6 from './images/shopping-6.png';

export default {
    memos: [
        {
            pairs: [
                {
                    text: 'chicken-egg paradox',
                    image: img1
                },
                {
                    text: 'red vegetable, "papr.."',
                    image: img2
                }
            ]
        },
        {
            pairs: [
                {
                    text: 'carefull when crossing the road',
                    image: img3
                },
                {
                    text: 'dark with green hair',
                    image: img4
                }
            ]
        },
        {
            pairs: [
                {
                    text: 'fractal',
                    image: img5
                },
                {
                    text: 'per gynt',
                    image: img6
                }
            ]
        }
    ]
};
