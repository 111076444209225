import m1p2 from './images-aim2north/m1p2.jpg';
import m1p3 from './images-aim2north/m1p3.jpg';
import m1p4 from './images-aim2north/m1p4.jpg';

import m2p2 from './images-aim2north/m2p2.jpg';
import m2p3 from './images-aim2north/m2p3.jpg';

import m3p2 from './images-aim2north/m3p2.jpg';
import m3p3 from './images-aim2north/m3p3.jpg';

import m4p2 from './images-aim2north/m4p2.jpg';
import m4p3 from './images-aim2north/m4p3.jpg';

import m5p2 from './images-aim2north/m5p2.jpg';
import m5p3 from './images-aim2north/m5p3.jpg';

export default {
    memos: [
        {
            pairs: [
                {
                    text: 'brain hack #0',
                    image: '0'
                },
                {
                    text: 'train your whole brain',
                    image: m1p2
                },
                {
                    text: 'safe reptile brain',
                    image: m1p3
                },
                {
                    text: 'balanced human brain',
                    image: m1p4
                }
            ]
        },
        {
            pairs: [
                {
                    text: 'brain hack #1',
                    image: '1'
                },
                {
                    text: 'focus',
                    image: m2p2
                },
                {
                    text: 'remove distractions',
                    image: m2p3
                }
            ],
            fakeOptions: [m5p3]
        },
        {
            pairs: [
                {
                    text: 'brain hack #2',
                    image: '2'
                },
                {
                    text: 'relationship building',
                    image: m3p2
                },
                {
                    text: 'remember names',
                    image: m3p3
                }
            ],
            fakeOptions: [m2p3]
        },
        {
            pairs: [
                {
                    text: 'brain hack #3',
                    image: '3'
                },
                {
                    text: 'creativity',
                    image: m4p2
                },
                {
                    text: 'combine thoughts to new ideas',
                    image: m4p3
                }
            ],
            fakeOptions: [m1p3]
        },
        {
            pairs: [
                {
                    text: 'brain hack #4',
                    image: '4'
                },
                {
                    text: 'motivation',
                    image: m5p2
                },
                {
                    text: 'body language influences your mood',
                    image: m5p3
                }
            ],
            fakeOptions: [m4p3]
        }
    ]
};
