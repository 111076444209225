import m0p2 from './images-sats/m0p2.jpg';
import m0p3 from './images-sats/m0p3.jpg';
import m0p4 from './images-sats/m0p4.jpg';
import m0p5 from './images-sats/m0p5.jpg';

import m1p2 from './images-sats/m1p2.jpg';
import m1p3 from './images-sats/m1p3.jpg';

import m2p2 from './images-sats/m2p2.jpg';
import m2p3 from './images-sats/m2p3.jpg';
import m2p4 from './images-sats/m2p4.jpg';

import m3p2 from './images-sats/m3p2.jpg';
import m3p3 from './images-sats/m3p3.jpg';
import m3p4 from './images-sats/m3p4.jpg';
import m3p5 from './images-sats/m3p5.jpg';
import m3p6 from './images-sats/m3p6.jpg';

import m4p2 from './images-sats/m4p2.jpg';
import m4p3 from './images-sats/m4p3.jpg';

import m5p2 from './images-sats/m5p2.jpg';
import m5p3 from './images-sats/m5p3.jpg';

export default {
    memos: [
        {
            pairs: [
                {
                    text: 'Ferdighet 0',
                    image: '0'
                },
                {
                    text: 'Tren hele hjernen',
                    image: m0p2
                },
                {
                    text: 'Trygg reptilhjerne',
                    image: m0p3
                },
                {
                    text: 'Glad emosjons-/pattedyrshjerne',
                    image: m0p4
                },
                {
                    text: 'Balansert menneskehjerne',
                    image: m0p5
                }
            ]
        },
        {
            pairs: [
                {
                    text: 'Ferdighet 1',
                    image: '1'
                },
                {
                    text: 'Fokus',
                    image: m1p2
                },
                {
                    text: 'Fjern forstyrrelser',
                    image: m1p3
                }
            ],
            fakeOptions: [m2p3]
        },
        {
            pairs: [
                {
                    text: 'Ferdighet 2',
                    image: '2'
                },
                {
                    text: 'Inspirasjon',
                    image: m2p2
                },
                {
                    text: 'Smil med hele kroppen',
                    image: m2p3
                },
                {
                    text: 'Hva kan du glede deg over?',
                    image: m2p4
                }
            ],
            fakeOptions: [m3p3]
        },
        {
            pairs: [
                {
                    text: 'Ferdighet 3',
                    image: '3'
                },
                {
                    text: 'Kommunikasjon',
                    image: m3p2
                },
                {
                    text: 'Repeter',
                    image: m3p3
                },
                {
                    text: 'Evaluer',
                    image: m3p4
                },
                {
                    text: 'Anekdoter for oversikt',
                    image: m3p5
                },
                {
                    text: 'Detaljer for beslutning',
                    image: m3p6
                }
            ]
        },
        {
            pairs: [
                {
                    text: 'Ferdighet 4',
                    image: '4'
                },
                {
                    text: 'Relasjonsbygging',
                    image: m4p2
                },
                {
                    text: 'Gi ærlig og oppriktig ros',
                    image: m4p3
                }
            ],
            fakeOptions: [m5p2]
        },
        {
            pairs: [
                {
                    text: 'Ferdighet 5',
                    image: '5'
                },
                {
                    text: 'Vaneendring',
                    image: m5p2
                },
                {
                    text: 'Bruk triggerne dine',
                    image: m5p3
                }
            ],
            fakeOptions: [m0p4]
        }
    ]
};
