import m1p2 from './images/m1p2.jpg'
import m1p3 from './images/m1p3.jpg'
import m1p4 from './images/m1p4.jpg'

import m2p2 from './images/m2p2.jpg'
import m2p3 from './images/m2p3.jpg'

import m3p2 from './images/m3p2.jpg'
import m3p3 from './images/m3p3.jpg'

import m4p2 from './images/m4p2.jpg'
import m4p3 from './images/m4p3.jpg'

import m5p2 from './images/m5p2.jpg'
import m5p3 from './images/m5p3.jpg'

import treeface from './images/treeface.jpg'
import catlion from './images/catlion.jpg'
import clock from './images/clock.jpg'
import lightbulbs from './images/lightbulbs.jpg'
import megaphonekids from './images/megaphonekids.jpg'
import reptile from './images/reptile.jpg'
import monkey from './images/monkey.jpg'
import sailboats from './images/sailboats.jpg'
import sailboat from './images/sailboat.jpg'
import ladder from './images/ladder.jpg'
import pistoltrigger from './images/pistoltrigger.jpg'
import emobrain from './images/emobrain.jpg'

export default {
  memos: [
    // 0 same
    {
      pairs: [
        {
          text: 'brain hack #0',
          image: '0'
        },
        {
          text: 'train your whole brain',
          image: m1p2
        },
        {
          text: 'safe reptile brain',
          image: m1p3
        },
        {
          text: 'balanced human brain',
          image: m1p4
        },
        {
          text: 'happy emotional brain',
          image: emobrain
        }
      ],
      fakeOptions: [sailboat]
    },
    // 1 same
    {
      pairs: [
        {
          text: 'brain hack #1',
          image: '1'
        },
        {
          text: 'focus',
          image: m2p2
        },
        {
          text: 'remove distractions',
          image: m2p3
        }
      ],
      fakeOptions: [m5p3]
    },
    // 2 new
    {
      pairs: [
        {
          text: 'brain hack #2',
          image: '2'
        },
        {
          text: 'productivity',
          image: clock
        },
        {
          text: 'prioritize',
          image: lightbulbs
        }
      ],
      fakeOptions: [m2p3]
    },
    // 3 same
    {
      pairs: [
        {
          text: 'brain hack #3',
          image: '3'
        },
        {
          text: 'creativity',
          image: m4p2
        },
        {
          text: 'combine thoughts to new ideas',
          image: m4p3
        }
      ],
      fakeOptions: [m1p3]
    },
    // 4 new
    {
      pairs: [
        {
          text: 'brain hack #4',
          image: '4'
        },
        {
          text: 'neuro-communication',
          image: megaphonekids
        },
        {
          text: 'repeat',
          image: reptile
        },
        {
          text: 'evaluate',
          image: monkey
        },
        {
          text: 'anecdotal overview',
          image: sailboats
        },
        {
          text: 'descriptive details',
          image: sailboat
        }
      ],
      fakeOptions: [m3p2]
    },
    // 5 prev no. 4
    {
      pairs: [
        {
          text: 'brain hack #5',
          image: '5'
        },
        {
          text: 'motivation',
          image: m5p2
        },
        {
          text: 'body language influences your mood',
          image: m5p3
        }
      ],
      fakeOptions: [m4p3]
    },
    // 6 new
    {
      pairs: [
        {
          text: 'brain hack #6',
          image: '6'
        },
        {
          text: 'habits',
          image: ladder
        },
        {
          text: 'trigger',
          image: pistoltrigger
        }
      ],
      fakeOptions: [m5p2]
    },
    // 7 new
    {
      pairs: [
        {
          text: 'brain hack #7',
          image: '7'
        },
        {
          text: 'mindset',
          image: catlion
        },
        {
          text: 'growth vs fixed mindset',
          image: treeface
        }
      ],
      fakeOptions: [m3p3]
    }
  ]
}
